@import '~antd/dist/antd.css';

div {
  color: #323276;
  font-size: 13px;
}

img {
  max-width: 100%;
}

.main-title, .ant-drawer-title{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #323276 !important;
}

.sub-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  color: #323276 !important;
}

.content, .ant-form label, .ant-checkbox-wrapper {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #323276 !important;
}

.ant-tag {
  font-size: 13px;
}

.ant-input[disabled]:hover, .ant-input[disabled], .ant-select-disabled .ant-select-selection,
.ant-input-number-disabled .ant-input-number-input,.addCriteriaButton.ant-btn[disabled], .ant-time-picker-input[disabled]{
  border: none;
  color:#8C8C8C!important;
  background-color: #f5f5f5;
}

.ant-form-explain, .ant-form-extra {
  min-height: 0px;
}

.ant-table-column-has-actions .ant-table-column-has-filters .ant-table-row-cell-break-word:hover {
  background-color: #f5f5f5;
}

.login-form {
  max-width: 300px;
  margin: auto;
  margin-top: 30%;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
#Footer-Login{
  padding: 12px 25px !important;
}

.ant-menu-inline-collapsed > .ant-menu-item .anticon{
  color: #ffffff !important;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 55px;
  margin: 8px 8px 5px;
  text-align: center;
  border-bottom: 1px solid #041E42;
}
SVG {
  vertical-align: baseline;
}

.ant-card .card-herder2 .ant-card-body{
  padding: 20px 0px;
}

.ant-card .card-herder3 .ant-card-head {
  color: #041E42;
  background-color: #D8EBE5 !important;
  padding-left: 10px !important;
  font-size: 14px !important;
  padding: 7px;
}

.ant-card.required >.ant-card-head > .ant-card-head-wrapper > .ant-card-head-title::after{
  content: " *";
  color: red;
}

.ant-card-head-title{
  padding: 0px;
  color: #041E42;
  font-weight: bold;
}


.ant-card-head{
  padding: 0%;
  min-height: 0px;
}

.ant-btn{
  border: 1px solid #323276;
  color: #323276;
  font-size: 13px;
}

.ant-btn:hover{
  color: #323276;
  border: 1px solid #323276;
  box-sizing: border-box;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 2px 2px 2px rgba(114, 104, 104, 0.05);
  font-size: 13px;
}

.ant-btn:focus{
  color: #323276;
  border: 1px solid #323276;
}

.workLogButton.ant-btn{
  color: #323276;
  margin-right: 10px;
}

.workLogButton.ant-btn:focus{
  color: #FA541C;
  border: 1px solid #FA541C;
  margin-right: 10px;
}

.ant-btn-primary {
  color: #ffffff !important;
  background-color: #FA541C;
  border-color: #FA541C;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-primary:hover{
  color: #ffffff !important;
  background-color: #FF7A45;
  border-color: #FF7A45;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-primary:focus {
  color: #ffffff !important;
  background-color: #FA541C;
  border-color: #FA541C;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-background-ghost.ant-btn-primary:hover{
  color: #ffffff !important;
  border-color: #FA541C;
}

.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 0px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}

.ant-btn-background-ghost.ant-btn-primary {
  color: #ffffff !important;
  background-color: transparent;
  border-color: #323276;
  text-shadow: none;
}

.ant-switch-checked {
  background-color: #FA541C !important;
}

.ant-switch{
  margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-width: 44px;
    height: 22px;
    line-height: 20px;
    vertical-align: middle;
    background-color: #d9d9d9;
    border: 1px solid transparent;
    border-radius: 100px;
    cursor: pointer;
    -webkit-transition: all 0.36s;
    transition: all 0.36s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* .ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed{
  background: #323276 !important;
  width: 70px !important;
  flex: 0 0 70px !important;
  max-width: 70px !important;
  min-width: 70px !important;
} */

.ant-layout-sider{
  background: #323276 !important;
}

.ant-menu-inline-collapsed {
  width: auto;
}

.ant-menu-dark, .ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background-color: #323276 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color: transparent;
  color: #FA541C;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected{
  background-color: #FA541C;
  border-radius: 5px;
}

.ant-menu-dark.ant-menu-inline .ant-menu-dark.ant-menu-vertical .ant-menu-dark.ant-menu-vertical-left .ant-menu-dark .ant-menu-vertical-right{
  padding-left:15px;
  padding-right: 15px;
}

.ant-form-item{
  margin-bottom: 5px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: auto;
}

.certain-search-item-count {
  position: absolute;
  color: #999;
}

.certain-category-search.ant-select-focused .certain-category-icon {
  color: #108ee9;
}

.certain-category-icon {
  color: #6e6e6e;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 16px;
}

.ant-steps-item-process .ant-steps-item-icon{
  background-color: #34547E !important;
}

.myCardSetting{
  padding: 20px;
  margin-bottom: 15px;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
  background-color: #F2F2F2;
  color: #323276;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active{
  background-color: #ffffff;
  color: #FA541C;
}

.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td {
  padding: 2px 13px;
}

.table td{
  vertical-align: inherit !important;
}

.edit_icon, .del_icon, .clone_icon, .scan_icon{
  color: #323276;
  cursor: pointer;
}

.edit_icon:hover, .del_icon:hover, .clone_icon:hover {
  color: #FA541C;
  cursor: pointer;
}

.scan_icon {
  color: #323276;
  font-size: 25px;
  cursor: pointer;
}

.scan_icon:hover {
  color: #FA541C;
  font-size: 25px;
  cursor: pointer;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 50px;
  margin-right: 8px;
}

.menu-setting-page {
  font-size: 14px;
  font-weight: 600;
  margin-left: 5px;
}

.logo_setting:hover{
  color: #041E42;
}

.ant-tooltip-inner{
  background-color:#FFF2E8;
  color: #FA541C !important;
}

.ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 8px;
  height: 8px;
  background-color:#FA541C;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}

.ant-radio-checked .ant-radio-inner {
  border-color: #FA541C;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FA541C;
  border-color: #FA541C;
}

.ant-pagination{
  font-size: 13px;
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #FA541C !important;
}

.ant-pagination-item-active a{
  color: #FA541C !important;
}

.ant-pagination-disabled a, .ant-pagination-disabled:hover a, .ant-pagination-disabled:focus a, .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus .ant-pagination-item-link{
  color: #FA541C;
  border-color: #FA541C;
  cursor: not-allowed;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  display: block;
  height: 100%;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  color: #323276;
  border: 1px solid #323276;
  border-radius: 4px;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ant-pagination-item {
  background-color: #fff;
  border: 1px solid #323276;
  color: #323276;
}

.ant-pagination-item:hover {
  background-color: #fff;
  border: 1px solid #FA541C;
  color: #FA541C;
}

.ant-pagination-item a{
  color: #323276;
}

.ant-pagination-item:focus a, .ant-pagination-item:hover a {
  color: #FA541C !important;
}

.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background: #ffffff;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #FFD8BF
}

.MyText-form{
  color: #323276;
  font-weight: normal;
  font-size: 13px;
}

.ant-btn-dashed:hover{
  border: 1px solid #FA541C;
  color: #FA541C;
}

.ant-btn-dashed{
  border:none;
  color: #323276;
}

.ant-modal-confirm-btns > .ant-btn:not([disabled]){
  text-decoration: none;
  color: #323276;
}

.ant-modal-confirm-btns > .ant-btn:not([disabled]):hover {
  text-decoration: none;
  color: #323276;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.05);
}

.ant-modal-confirm-btns > .ant-btn.ant-btn-danger{
  color: #FFFFFF;
  border: 1px solid #FA541C;
  background: #FA541C;
}

.ant-modal-confirm-btns > .ant-btn.ant-btn-danger:hover{
  text-decoration: none;
  background-color: #FA541C;
  color: #fff;
  border: 1px solid #FA541C;
}

.ant-radio-wrapper::after{
  background-color: #323276;
  color: #323276;
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner, .ant-select-selection {
  border-color: #323276;
}

.ant-table-column-title, .topicName, .ant-select-dropdown-menu-item, .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
  color: #323276;
  font-size: 13px;
}

.ant-table-row-expand-icon:focus, .ant-table-row-expand-icon:hover, .ant-descriptions-title {
  color: #323276;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{
  background-color: #FFF2E8;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #FFD8BF;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #FFD8BF;
}

.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #323276;
  border-radius: 2px;
  border-collapse: separate;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ant-checkbox .ant-checkbox-indeterminate{
  background-color: #FA541C;
  color: #FA541C;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #323276;
  border: 1px solid #323276;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
  border: 1px solid #323276;
}

.ant-table-thead > tr > th{
  padding: 12px 16px;
}

tr.ant-table-expanded-row{
  background-color:#f2f2f2;
}

tr.ant-table-expanded-row:hover{
  background-color:#f2f2f2;
}
tr.table-noherder.ant-table-thead > tr:first-child > th:last-child{
  display: none;
}

.ant-layout.ant-layout-has-sider{
  flex-direction: row;
    height: -webkit-fill-available;
}

.ant-checkbox + span{
  padding-right: 0px;
  padding-left: 8px;
  font-weight: normal!important;
}

.ant-dropdown-menu-item:hover,  .ant-dropdown-menu-submenu-title:hover{
  background-color:#FFD8BF
}

.ck-editor__editable_inline {
  min-height: 150px;
}

.tagSLA{
   background-color: #2F80ED;
   color: #fff;
 }

.table-config-header thead{
  display: none;
  margin-top: 3px;
}

.sladisplay-stage-complete{
  color: #C5C3C8;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ql-editor{
  min-height: 100px !important;
  max-height: 200px;
  overflow: hidden;
  overflow-y: scroll;
}

/*.activate{*/
/*  display: flex;*/
/*  float: right;*/
/*}*/

.ant-input, .ant-input:hover, .ant-input:focus, .ant-time-picker-input {
  border-color: #323276;
  border-right-width: 1px !important;
  font-size: 13px;
  color: #323276;
}

.ant-select-selection--multiple , .ant-select-selection--multiple:hover, .ant-select-selection--multiple:focus{
  min-height: 32px;
  padding-bottom: 3px;
  cursor: text;
  zoom: 1;
  border: 1px solid #323276;
}

.ant-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: aliceblue;
  border: 1px solid #323276;
  border-radius: 4px;
  outline: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -moz-appearance: textfield !important;
}

.ant-select-auto-complete.ant-select .ant-input:focus, .ant-select-auto-complete.ant-select .ant-input:hover .ant-input:placeholder-shown{
  border-color: #323276;
  border-right-width: 1px !important;
  font-size: 13px;
}

.ant-select-arrow svg{
  display: inline-block;
  color: #323276;
}

.ant-select-disabled .ant-select-selection .ant-select-arrow svg{
  display: inline-block;
  color: inherit !important;
}

.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice .ant-select-selection__choice__content {
  color: #8c8c8c!important;
}

.ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
  color: #FA541C;
  border-color: #FA541C;
}

.ant-time-picker-input {
  border: 1px solid #323276;
}

.label-requester {
  color: #6A6C8F;
  margin-right: 10px;
}

.selectStyleRed.ant-dropdown-link.ant-dropdown-trigger{
  color: red;
  margin-right: 5px;
  margin-left: 5px;
}

.selectStyleBlack.ant-dropdown-link.ant-dropdown-trigger{
  color: #323276!important;
  margin-right: 5px;
  margin-left: 5px;
}

.has-error .ant-form-explain, .has-error .ant-form-split {
  font-size: 13px;
  margin-top: 2px;
}

.disabledStatus.ant-input[disabled] {
  background: #E0E0E0;
  border-color: #E0E0E0!important;
  color: #323276!important;
}

.background-form-criteria {
  background-color: #FFF2E8;
  border: 15px #FFF2E8;
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 20px;
  column-rule:10px outset pink;
  position: relative;
  width: 100%;
}

.addCriteriaButton.ant-btn {
  border: 1px solid #FA541C;
  color: #FA541C;
  font-size: 13px;
}

.addCriteriaButton.ant-btn:hover {
  box-shadow: 2px 2px 2px rgba(114, 104, 104, 0.2);
}

.verticalLine {
  border: 0.4px solid #323276;
  height: -webkit-fill-available;
  position: absolute;
  margin-top: 30px;
  background-color: #323276;
}

.requiredIcon {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.deleteButton {
  padding: 10px;
  color: #FA541C;
  border: #FA541C;
  font-size: 13px;
}

.deleteButton:hover {
  color: #FA541C;
  border: #FA541C;
  font-size: 13px;
}

.disabled-icon{
  padding: 10px;
  color: #FA541C;
  font-size: 13px;
  opacity: 0.5;
  pointer-events: none;
}

.ant-time-picker-icon .ant-time-picker-clock-icon, .ant-calendar-picker-icon{
  color: inherit !important;
}

.ant-time-picker.enable-icon > .ant-time-picker-icon{
  color: #323276;
}

.ant-time-picker-input[disabled].ant-time-picker-icon > .ant-time-picker-clock-icon, .ant-calendar-picker-input.ant-input.ant-input-disabled.ant-calendar-picker-icon{
  color: rgba(0, 0, 0, 0.25);
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  padding: 8px 10px;
}

.ant-modal-title {
  color: #323276;
  font-size: 16px;
  font-weight: 500;
}

.border-current-view {
  border: 1px solid #FFD8BF;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 7px;
  margin-right: 40px;
}

.border-height-default {
  min-height: 830px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #323276;
}

.ant-btn-primary[disabled] {
  color: #A9A9A9!important;
}

.ant-select.ant-select-disabled > .ant-select-selection.ant-select-selection--single > .ant-select-selection__rendered > .ant-select-selection-selected-value {
  color: #8c8c8c!important
}


.selectBorder.ant-select.ant-select-enabled.ant-select-allow-clear> .ant-select-selection.ant-select-selection--single, .inputRequire.ant-input:placeholder-shown {
  border-color: #FA541C!important;
}

.ql-indent-1:not(.ql-direction-rtl){
  padding-left: 3em;
}

.ql-indent-2:not(.ql-direction-rtl){
  padding-left: 6em;
}

.ql-indent-3:not(.ql-direction-rtl){
  padding-left: 9em;
}

.ql-indent-4:not(.ql-direction-rtl){
  padding-left: 12em;
}

.ql-indent-5:not(.ql-direction-rtl){
  padding-left: 15em;
}

.showHoliday {
  border-color: #323276;
  color: #323276;
  width: 100%;
  height: 35px;
  padding: 7px;
}

.disable-showHoliday{
  background-color: #f5f5f5 !important;
  border: none;
  color: #323276;
  width: 100%;
  height: 35px;
  padding: 7px;
}

/*.inputRequire.ant-input:placeholder-shown {*/
/*  border-color: #FA541C!important;*/
/*}*/

.compact-picker {
  width: 515px!important;
}

.customWidth.compact-picker {
  width: 240px!important;
}

.ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-nav .ant-tabs-tab:hover {
  color: #FA541C;
}
.ant-tabs-ink-bar {
  background-color: #FA541C;
}

.custom-card {
  height: 100%;
}

.custom-card > .ant-card-body {
  width: 100%;
  height: 100%;
}

.custom-position-button {
  position: absolute;
  bottom: 5%;
  right: 1%;
}

@keyframes example {
  from {background-color: #fff;}
  to {background-color:#FFD8BF;opacity:0.75}
}

.custom-highlight table > tbody >:first-child{
  color: #323276;
  background-color: #fff;
  animation-name: example;
  animation-duration: 1.5s;
}

.icon {
  width: 30px;
  height: 30px;
}

.Box-DashBoard {
  border: '1px solid #E0E0E0';
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.TopicDashboard {
  background-color: #E1ECF5;
  padding: 10px;
}

.TextFrontDesc {
  color: #6A6C8F;
  font-size: 12px;
}

.fix_scroll_md {
  min-width: '768px';
  overflow: auto;
}

.Desc_text_gray {
  margin-left: 9%;
  color: #8C8C8C;
}

.Desc_text_gray_2 {
  color: #323276;
}

.BG_Gray {
  background-color: #F2F2F2;
  padding: 10px;
}

.BG_orange {
  background-color: #FFF2E8;
  padding: 10px;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 300px;
}

.ant-modal-header {
  border-bottom: 0px;
}

.FontTest {
  font-family: "Times New Roman", Times, serif;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #fa541c;
  background: #fff;
  border-color: #fa541c;
  -webkit-box-shadow: -1px 0 0 0 #fa541c;
  box-shadow: -1px 0 0 0 #fa541c;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #fa541c;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ant-radio-button-wrapper:hover {
  position: relative;
  color: #fa541c;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #fa541c !important;
  opacity: 0.1;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fa541c;
  border-color: #fa541c;
  -webkit-box-shadow: -1px 0 0 0 #fa541c;
  box-shadow: -1px 0 0 0 #fa541c;
}

#submit-button {
  transition: transform 1s;
} 

#submit-button:focus {
  transform: scale(1.2,1.2);
}

/* workaround case can't click everthing on website*/
iframe {
    display: none;
}
